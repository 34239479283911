<template>
  <div class="feedback-content">
    <div class="header">
      <div class="text">
        <h1>FEEDBACK</h1>
      </div>
    </div>
    <el-card class="box-card">
      <div v-if="feedbackCount" style="margin-top: 10px">
        <el-row :gutter="10">
          <el-table
              :data="feedbackList"
              border
              :row-class-name="tableRowClassName"
              style="width: 100%">
            <el-table-column
                prop="create_date"
                label="Time"
                width="190">
            </el-table-column>
            <el-table-column
                prop="no"
                label="NO"
                width="150">
            </el-table-column>
            <el-table-column
                prop="name"
                label="Name"
                width="150">
            </el-table-column>
            <el-table-column
                prop="email"
                label="Email"
                width="260">
            </el-table-column>
            <el-table-column
                prop="message"
                label="Message">
            </el-table-column>
          </el-table>
        </el-row>
        <el-pagination
          style="margin-top: 80px; text-align: right; width: 100%"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-size="queryData.pageSize"
          :total="feedbackCount"
          layout="sizes,total, prev, pager, next,jumper"
        >
        </el-pagination>
      </div>
      <el-empty v-else description="You have not submitted any contact messge"></el-empty>
    </el-card>
  </div>
</template>

<script>
import { userFeedback } from "@/api/feedback";
export default {
  data() {
    return {
      feedbackList: [],
      feedbackCount: 0,
      queryData: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getUserFeedback();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (row.status  === 1) {
        return 'success-row';
      }
      return '';
    },
    getUserFeedback() {
      const token = localStorage.getItem("token");
      userFeedback({
        token: token,
        pageSize: this.queryData.pageSize,
        page: this.queryData.page,
      }).then((res) => {
        this.feedbackList = res.data.list;
        this.feedbackCount = res.data.count;
      });
    },
    handleSizeChange(val) {
      this.queryData.pageSize = val;
      this.getUserFeedback();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.queryData.page = val;
      this.getUserFeedback();
    },
  },
};
</script>
<style>
.el-table .success-row {
  color: #009900;
}

.header {
  background: url(../../assets/imgs/home/texture-bw.png) center center repeat;
  height: 113px;
.text {
  width: 1170px;
  margin: 0 auto;
  line-height: 113px;
  font-size: 20px;
}
h1 {
  font-weight: bolder;
  line-height: 113px;
  margin-top: 0px;
}
}
</style>
